import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import myCustomTheme from "../../app.theme";
import { AddIcon } from "../../assests/images";
import { AppRoutings } from "../../utility/enums/app-routings";
import { SortOrder } from "../../utility/enums/sort-order";
import { IChatBoxInfo } from "../../utility/interfaces/common/chat-box-info";
import { ISearchCriteria } from "../../utility/interfaces/common/search-criteria";
import { IDashboardInfo } from "../../utility/interfaces/dashboard/dashboard-info";
import { ChatBox } from "../common/chat-box";
import { TablePagination } from "../common/table-pagination";
import { CreateRequestModal } from "./create-request-modal";
import { TableRowData } from "./table-row";
import { AccountType, Constants } from "../../utility/enums/constants";
import { UserContext } from "../../contexts/user-context";
import { HubConnectionBuilder } from "@microsoft/signalr";
import {
  createCommonAPICall,
  hideLoader,
  showLoader,
} from "../../utility/helpers";
import { toast } from "react-toastify";
import dashboardService from "../../services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";

const URL = Constants.HubUrl;

type ComponentProps = {
  searchCriteria: ISearchCriteria;
  handleOnSearchCriteriaChange: any;
  dashboardPageInfo: IDashboardInfo | null;
};

export function DashboardTable(props: ComponentProps) {
  // use context
  const contextUser = useContext(UserContext);
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // Page level variables
  const navigate = useNavigate();

  // Extract Props
  const { searchCriteria, handleOnSearchCriteriaChange, dashboardPageInfo } =
    props;

  // useStates
  const [openChatBoxList, setOpenChatBoxList] = useState<IChatBoxInfo[]>([]);
  const [isCreateRequest, setIsCreateRequest] = useState(false);
  const [isRequestForMe, setIsRequestForMe] = useState(true);
  const [connection, setConnection] = useState<null | signalR.HubConnection>(
    null
  );

  useEffect(() => {
    if (URL) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(URL)
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");
          connection.on("SetChatHistoryList", (data) => {
            console.log(data);
            if (data) setOpenChatBoxList(data.chatresponse);
            const request = data.chatresponse;
            const openChatBoxes = openChatBoxList.filter(
              (box) =>
                box.receiverId === request.recieverId &&
                box.senderId === request.senderId
            );
            if (!openChatBoxes || openChatBoxes.length === 0) {
              setOpenChatBoxList([
                ...openChatBoxList,
                {
                  receiverId: request.receiverId,
                  receiverName: request.receiverName,
                  senderId: request.senderId,
                  senderName: request.senderName,
                  isMinimized: false,
                  confirmationNumber: request.confirmationNumber,
                  isAdmin: request.isAdmin != 0,
                  chatView: data.chatView,
                  receiverProfilePath: data.chatresponse.receiverProfilePath,
                },
              ]);
              hideLoader();
              return;
            }
            const chatBoxes = openChatBoxList.filter(
              (box) =>
                box.receiverId !== request.receiverId ||
                box.senderId !== request.senderId
            );
            setOpenChatBoxList([
              ...chatBoxes,
              {
                receiverId: request.receiverId,
                receiverName: request.receiverName,
                senderId: request.senderId,
                senderName: request.senderName,
                isMinimized: false,
                confirmationNumber: request.confirmationNumber,
                isAdmin: request.isAdmin != 0,
                chatView: data.chatView,
                receiverProfilePath: data.chatresponse.receiverProfilePath,
              },
            ]);
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  useEffect(() => {
    if (connection)
      connection.on("SendPrivateMessage", (data) => handlePrivateMessage(data));
  }, [openChatBoxList]);

  const handlePrivateMessage = (data: any) => {
    console.log("SendPrivateMessage", data, openChatBoxList);
    if (
      openChatBoxList.find(
        (chat) =>
          chat.confirmationNumber == data.chatresponse.confirmationNumber
      )
    ) {
      if (data) setOpenChatBoxList(data.chatresponse);
      const request = data.chatresponse;
      const openChatBoxes = openChatBoxList.filter(
        (box) =>
          box.receiverId === request.receiverId &&
          box.senderId === request.senderId
      );
      if (!openChatBoxes || openChatBoxes?.length === 0) {
        setOpenChatBoxList([
          ...openChatBoxList,
          {
            receiverId: request.receiverId,
            receiverName: request.receiverName,
            senderId: request.senderId,
            senderName: request.senderName,
            isMinimized: false,
            confirmationNumber: request.confirmationNumber,
            isAdmin: request.isAdmin != 0,
            chatView: data.chatView,
            receiverProfilePath: data.chatresponse.receiverProfilePath,
          },
        ]);
        hideLoader();
        return;
      }
      const chatBoxes = openChatBoxList.filter(
        (box) =>
          box.receiverId !== request.receiverId ||
          box.senderId !== request.senderId
      );
      setOpenChatBoxList([
        ...chatBoxes,
        {
          receiverId: request.receiverId,
          receiverName: request.receiverName,
          senderId: request.senderId,
          senderName: request.senderName,
          isMinimized: false,
          confirmationNumber: request.confirmationNumber,
          isAdmin: request.isAdmin != 0,
          chatView: data.chatView,
          receiverProfilePath: data.chatresponse.receiverProfilePath,
        },
      ]);
    }
  };

  // Handled events and functions
  const handleOnPageNumberChange = (value: number) => {
    handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
  };
  const handleOnCloseChat = async (
    receiverId: number,
    senderId: number,
    senderTypeId: number,
    receiverTypeId: number
  ) => {
    console.log(receiverId, senderId, senderTypeId, receiverTypeId);
    const openChatBoxes = openChatBoxList.filter(
      (box) => box.receiverId !== receiverId || box.senderId !== senderId
    );
    setOpenChatBoxList([...openChatBoxes]);

    await connection!.send(
      "GetDisconnect",
      senderId,
      senderTypeId,
      receiverId,
      receiverTypeId
    );
  };
  const handleOnMinimized = (receiverId: number, senderId: number) => {
    const chatBoxes = openChatBoxList.map((box) => {
      if (box.receiverId === receiverId && box.senderId === senderId) {
        box.isMinimized = !box.isMinimized;
      }
      return box;
    });
    setOpenChatBoxList(chatBoxes);
  };

  const handleOpenChatBox = async (
    userId: number,
    requestId: number,
    receiverId: number,
    receiverName: string,
    confirmationNumber: string,
    isChatWithAdmin: boolean
  ) => {
    const senderId = requestId;
    const senderTypeId = AccountType.Patient;
    const senderName = contextUser.lastName + " " + contextUser.firstName;
    const recieverTypeId = isChatWithAdmin
      ? AccountType.Admin
      : AccountType.Physician;
    const temp = {
      senderId,
      senderName,
      senderTypeId,
      receiverId,
      receiverName,
      recieverTypeId,
      confirmationNumber,
      userId,
    };
    console.log(temp);

    showLoader();
    await connection!.send(
      "hubconnect",
      senderId,
      senderTypeId,
      senderName,
      receiverId,
      recieverTypeId,
      requestId,
      confirmationNumber,
      userId
    );
  };

  const sendMessage = async (
    message: string,
    senderId: number,
    receiverId: number,
    receiverAccountType: AccountType,
    confirmationNumber: string,
    senderAccountType: AccountType
  ) => {
    console.log("sendMessage", receiverAccountType);
    await connection!.send(
      "SendPrivateMessage",
      message,
      senderId,
      receiverId,
      receiverAccountType,
      confirmationNumber,
      senderAccountType,
      "",
      0
    );
  };

  const sendAudioVideo = (
    file: Blob,
    senderId: number,
    receiverId: number,
    receiverAccountType: AccountType,
    confirmationNumber: string,
    senderAccountType: AccountType
  ) => {
    console.log("File Sent");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result && typeof reader.result == "string") {
        const chatUploadMedia = {
          File: reader.result,
          SenderId: senderId,
          ReceiverId: receiverId,
          ReceiverAccountTypeId: receiverAccountType,
          ConfirmationNumber: confirmationNumber,
          SenderAccountTypeId: senderAccountType,
        };
        console.log(chatUploadMedia);
        createCommonAPICall({
          requestBody: chatUploadMedia,
          apiService: dashboardService.uploadAudioVideoForChat,
          showSuccessMessage: false,
          showErrorMessage: false,
          setSuccessErrorContext,
        }).then(async (data) => {
          console.log(data.data);
          let msg = "";
          let path = data.data[0].path;
          // let path = CLOUD_URL + data.data[0].path;
          // if (file.type.includes("audio")) {
          //   msg = `<audio controls src="${path}"></audio>`;
          // } else if (file.type.includes("video")) {
          //   msg = `<video controls src="${path}"></video>`;
          // }
          if (data.data[0].status) {
            await connection!.send(
              "SendPrivateMessage",
              path,
              senderId,
              receiverId,
              receiverAccountType,
              confirmationNumber,
              senderAccountType,
              file.type,
              0
            );
          } else {
            toast.error(data.data[0].message);
          }
        });
      }
    };
  };

  const keyDownHandler = async (
    event: React.KeyboardEvent<HTMLInputElement>,
    message: string,
    senderId: number,
    receiverId: number,
    receiverAccountType: AccountType,
    confirmationNumber: string,
    senderAccountType: AccountType
  ) => {
    console.log("keyDownHandler");
    if (event.code === "Enter" && message !== "") {
      await connection!.send(
        "SendPrivateMessage",
        message,
        senderId,
        receiverId,
        receiverAccountType,
        confirmationNumber,
        senderAccountType,
        "",
        0
      );
    }
  };

  const updateChatBoxClasses = (openChatBoxList: IChatBoxInfo[]) => {
    const openChatBoxes = openChatBoxList.filter((box) => !box.isMinimized);
    openChatBoxes.length > 0
      ? document.body.classList.toggle("chatbox-open", true)
      : document.body.classList.toggle("chatbox-open", false);
  };

  const handleOnChangeSortDirection = (sortBy: string) => {
    handleOnSearchCriteriaChange({
      ...searchCriteria,
      PageIndexId: 0,
      SortBy: sortBy,
      SortOrder:
        searchCriteria.SortOrder === SortOrder.ascending
          ? SortOrder.descending
          : SortOrder.ascending,
    });
  };

  // useEffects
  useEffect(() => {
    updateChatBoxClasses(openChatBoxList);
  }, [openChatBoxList]);

  return (
    <>
      <Box className="table-box">
        <div className="chatbox-overlay"></div>
        <div className="attachment table-header">
          <Box
            className="selected-action-btn"
            sx={{
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "14px",
            }}
          >
            <Tooltip title="Create New Request">
              <Button
                variant="outlined"
                disableElevation
                disableRipple
                disableFocusRipple
                sx={{
                  minWidth: "111px",
                  [myCustomTheme.breakpoints.down("sm")]: {
                    minWidth: "0",
                  },
                }}
                onClick={() => setIsCreateRequest(true)}
              >
                <img src={AddIcon} alt="download" />
                <span>Create New Request</span>
              </Button>
            </Tooltip>
          </Box>
        </div>
        {dashboardPageInfo && dashboardPageInfo?.totalRecords > 0 ? (
          <>
            <TableContainer sx={{ display: { xs: "none", laptop: "block" } }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell sx={{ minWidth: 130 }}>
                      <TableSortLabel
                        active={searchCriteria.SortBy === "clientMember"}
                        direction={
                          searchCriteria.SortOrder === SortOrder.ascending
                            ? "asc"
                            : "desc"
                        }
                        onClick={() =>
                          handleOnChangeSortDirection("clientMember")
                        }
                      >
                        Client/Member
                      </TableSortLabel>
                    </TableCell> */}
                    <TableCell sx={{ minWidth: 150 }}>
                      <TableSortLabel
                        active={searchCriteria.SortBy === "createdDate"}
                        direction={
                          searchCriteria.SortOrder === SortOrder.ascending
                            ? "asc"
                            : "desc"
                        }
                        onClick={() =>
                          handleOnChangeSortDirection("createdDate")
                        }
                      >
                        Created Date
                      </TableSortLabel>
                    </TableCell>
                    {/* <TableCell sx={{ minWidth: 130 }}>
                      <TableSortLabel
                        active={searchCriteria.SortBy === "confirmation"}
                        direction={
                          searchCriteria.SortOrder === SortOrder.ascending
                            ? "asc"
                            : "desc"
                        }
                        onClick={() =>
                          handleOnChangeSortDirection("confirmation")
                        }
                      >
                        Confirmation
                      </TableSortLabel>
                    </TableCell> */}
                    {/* <TableCell sx={{ minWidth: 130 }}>
                      <TableSortLabel
                        active={searchCriteria.SortBy === "providerName"}
                        direction={
                          searchCriteria.SortOrder === SortOrder.ascending
                            ? "asc"
                            : "desc"
                        }
                        onClick={() =>
                          handleOnChangeSortDirection("providerName")
                        }
                      >
                        Provider
                      </TableSortLabel>
                    </TableCell> */}
                    {/* <TableCell sx={{ minWidth: 170 }}>
                      <TableSortLabel
                        active={searchCriteria.SortBy === "concludedDate"}
                        direction={
                          searchCriteria.SortOrder === SortOrder.ascending
                            ? "asc"
                            : "desc"
                        }
                        onClick={() =>
                          handleOnChangeSortDirection("concludedDate")
                        }
                      >
                        Concluded Date
                      </TableSortLabel>
                    </TableCell> */}
                    <TableCell sx={{ minWidth: 160 }}>
                      <TableSortLabel
                        active={searchCriteria.SortBy === "currentStatus"}
                        direction={
                          searchCriteria.SortOrder === SortOrder.ascending
                            ? "asc"
                            : "desc"
                        }
                        onClick={() =>
                          handleOnChangeSortDirection("currentStatus")
                        }
                      >
                        Current Status
                      </TableSortLabel>
                    </TableCell>
                    {/* <TableCell align="center" sx={{ minWidth: 130 }}>
                      Final Report
                    </TableCell> */}
                    <TableCell align="center" sx={{ minWidth: 130 }}>
                      Chat With
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: 130 }}>
                      Document
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardPageInfo?.requests?.map((row, index) => (
                    <TableRowData
                      key={index}
                      row={row}
                      isSmallDevice={false}
                      handleOpenChatBox={handleOpenChatBox}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: { xs: "block", laptop: "none" } }}
              className="tabledata-cards-group"
            >
              {dashboardPageInfo?.requests?.map((row, index) => (
                <TableRowData
                  key={index}
                  row={row}
                  isSmallDevice={true}
                  handleOpenChatBox={handleOpenChatBox}
                />
              ))}
            </Box>
            <TablePagination
              currentPageNumber={searchCriteria.PageIndexId}
              handleOnPageNumberChange={handleOnPageNumberChange}
              totalRecords={dashboardPageInfo?.totalRecords || 0}
              rowCount={dashboardPageInfo?.requests?.length || 0}
            />
          </>
        ) : (
          <Typography variant="h5" sx={{ padding: 3 }}>
            No Record(s) Found
          </Typography>
        )}
      </Box>
      {openChatBoxList.length > 0 && (
        <>
          {openChatBoxList.map((chatBox, index) => {
            return (
              <ChatBox
                index={index}
                handleOnMinimized={handleOnMinimized}
                key={index}
                handleOnCloseChat={handleOnCloseChat}
                chatBoxInfo={chatBox}
                sendMessage={sendMessage}
                onKeyHandler={keyDownHandler}
                sendAudioVideo={sendAudioVideo}
              />
            );
          })}
        </>
      )}
      <CreateRequestModal
        onClickContinue={() =>
          navigate(AppRoutings.CreateNewRequest, {
            state: {
              isRequestForMe: isRequestForMe,
            },
          })
        }
        isRequestForMe={isRequestForMe}
        onChangeRequestType={(type: boolean) => setIsRequestForMe(type)}
        handleOnModalClose={() => setIsCreateRequest(false)}
        isModalOpen={isCreateRequest}
      />
    </>
  );
}
