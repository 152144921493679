// Local config
// export const BASE_API_URL = `https://localhost:7080/`;
// export const ENVIRONMENT = `dev`;
// export const CLOUD_URL = `https://dtystorage.blob.core.windows.net/staging/`;

// GCP stage cloud Url
// export const CLOUD_URL = `https://storage.googleapis.com/download/storage/v1/b/dtystage/o/`;

// Stage config
//export const BASE_API_URL = `https://web2.anasource.com/dtyapi/`;
// export const BASE_API_URL = `http://172.174.160.184:81/`;
//export const BASE_API_URL = `http://34.148.250.95:91/`; // New GCP Stage Api

// Live Config
export const BASE_API_URL = `https://api2.doctorstoyou.com/`;
export const ENVIRONMENT = `production`;
// export const CLOUD_URL = `https://dtystorage.blob.core.windows.net/production/`;

// GCP live cloud Url
export const CLOUD_URL = `https://storage.googleapis.com/download/storage/v1/b/dty/o/`;

export const ANONYMOUS_KEY = `DTY-Anonymous`;
export const APPLICATION = `Web`;
export const APPLICATION_TYPE = `Patient`;