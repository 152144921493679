import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocationIcon } from "../../assests/images";
import { IValidationParms } from "../../utility/interfaces/common/validation-parms";
import { IPatientDetails } from "../../utility/interfaces/create-request/create-request-details";
import CustomDatePicker from "../common/custom-date-picker";
import { UploadDocument } from "../common/upload-document";
import { useContext, useState } from "react";
import { createCommonAPICall } from "../../utility/helpers";
import authService from "../../services/auth-service";
import { PhoneTypeModal } from "../common/phonetype-modal";
import createRequestService from "../../services/create-request-service";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import { CreateRequestType } from "../../utility/enums/create-request";
import { SelectResponsiblePartyModal } from "./select-responsible-party-modal";
import IntlTelInput from "react-intl-tel-input-18";
import CustomIntlTelInput from "../common/custom-intl-tel-input";

type ComponentProps = {
  patientDetails: IPatientDetails;
  onChangePatientDetails: any;
  isShowUploadOption?: boolean;
  validationParams: IValidationParms | undefined;
  setNewUser?: any;
  setRequestGuid?: any;
  setFileName?: any;
  requestTypeId: number;
  onSelectResponsibleParty?: any;
  isPhoneNumberValid?: boolean;
  setIsPhoneNumberValid?: any;
};

const PatientDetails = (props: ComponentProps) => {
  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // Extract Props
  const {
    patientDetails,
    onChangePatientDetails,
    isShowUploadOption = true,
    validationParams,
    setNewUser,
    setRequestGuid,
    setFileName,
    requestTypeId,
    onSelectResponsibleParty,
    isPhoneNumberValid,
    setIsPhoneNumberValid,
  } = props;

  // use state
  const [isNewUser, setIsNewUser] = useState(false);
  const [phoneTypeModelOpen, setphoneTypeModelOpen] = useState(false);
  const [responsiblePartyModalOpen, setResponsiblePartyModalOpen] =
    useState(false);
  //const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  // Handled event(s)
  const handlePhoneTypeModelOpen = () => setphoneTypeModelOpen(true);

  const handlePhoneTypeModelClose = () => {
    setphoneTypeModelOpen(false);
    if (requestTypeId !== CreateRequestType.patient) {
      setResponsiblePartyModalOpen(true);
    }
  };

  const onRadioChange = (event: any) => {
    onChangePatientDetails("radioClientPhoneType", event);
    // handlePhoneTypeModelClose();
  };

  const onSelectNewFiles = async (event: any) => {
    const fileNames: string[] = [];
    if (event.target.files && event.target.files.length > 0) {
      const formData = new FormData();
      Array.prototype.forEach.call(event.target.files, (file: File) => {
        fileNames.push("C:\\fakepath\\" + file.name);
      });
      onChangePatientDetails("documentName", fileNames?.toString());
      console.log(event.target.files);
      for (var i = 0; i < event.target.files.length; i++) {
        formData.append("files", event.target.files[i]);
      }

      const data = await createCommonAPICall({
        requestBody: formData,
        apiService: createRequestService.uploadFileForRequest,
        showErrorMessage: true,
        showSuccessMessage: false,
        setSuccessErrorContext,
      });

      if (data && data.isSuccessfull) {
        console.log(data);
        if (data.data.uploadStatuses[0].status)
          setRequestGuid(data.data.requestGuid);
        if (data.data.fileName) setFileName(data.data.fileName);
      }
    }
  };

  const checkRegisteredUser = async () => {
    if (requestTypeId === CreateRequestType.patient) {
      if (patientDetails.email !== "") {
        const data = await createCommonAPICall({
          requestBody: {
            userName: patientDetails.email,
          },
          apiService: authService.checkRegisteredUser,
        });

        if (data && data.isSuccessfull) {
          console.log(data.data);
          setIsNewUser(data.data === 0);
          setNewUser(data.data === 0);
        }
        console.log(patientDetails.email);
      } else {
        setIsNewUser(false);
        if (setNewUser) setNewUser(false);
      }
    } else {
      if (patientDetails.email != "") setResponsiblePartyModalOpen(true);
    }
  };

  const handleOnClickOpenMap = () => {
    let address = `${patientDetails.street} ${patientDetails.city} ${patientDetails.state} ${patientDetails.zipCode}`;
    const url = `http://www.google.com/maps?q=${address}`;
    window.open(url, "_blank");
  };

  const handlePhoneNumberChange = (
    isValid: any,
    phoneNumber: any,
    countryData: any
  ) => {
    setIsPhoneNumberValid(isValid);
    let formattedPhoneNumber =
      phoneNumber != ""
        ? phoneNumber.includes("+")
          ? phoneNumber
          : `+${countryData.dialCode} ${phoneNumber}`
        : "";
    onChangePatientDetails("phoneNumber", formattedPhoneNumber);
  };

  return (
    <>
      <Typography variant="h4">Patient Information</Typography>
      <div>
        <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
          <Grid item xs={12} sm={12}>
            <TextField
              {...validationParams?.register("symptoms")}
              name="symptoms"
              id="symptoms"
              label="Enter Brief Details Of Symptoms"
              value={patientDetails.symptoms}
              multiline
              maxRows={1}
              inputProps={{
                style: { minHeight: "88px" },
              }}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
              error={validationParams?.errors?.symptoms?.message ? true : false}
              helperText={validationParams?.errors?.symptoms?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...validationParams?.register("firstName")}
              name="firstName"
              id="firstName"
              label="First Name"
              value={patientDetails.firstName}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
              error={
                validationParams?.errors?.firstName?.message ? true : false
              }
              helperText={validationParams?.errors?.firstName?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...validationParams?.register("lastName")}
              error={validationParams?.errors?.lastName?.message ? true : false}
              helperText={validationParams?.errors?.lastName?.message?.toString()}
              name="lastName"
              id="lastName"
              label="Last Name"
              value={patientDetails.lastName}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              disabled={false}
              label="Date Of Birth"
              name="birthDate"
              value={patientDetails.birthDate}
              onChange={onChangePatientDetails}
              register={validationParams?.register}
              error={
                validationParams?.errors?.birthDate?.message ? true : false
              }
              message={validationParams?.errors?.birthDate?.message}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant="h4">Patient Contact Information</Typography>
        <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...validationParams?.register("email")}
              error={validationParams?.errors?.email?.message ? true : false}
              helperText={validationParams?.errors?.email?.message?.toString()}
              name="email"
              id="email"
              label="Email"
              value={patientDetails.email}
              fullWidth
              onChange={(e) => {
                onChangePatientDetails(e.target.name, e.target.value);
              }}
              onBlurCapture={checkRegisteredUser}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomIntlTelInput
              isPhoneNumberValid={isPhoneNumberValid ?? true}
              onChange={handlePhoneNumberChange}
              onBlur={() => {
                if (patientDetails.phoneNumber !== "") {
                  handlePhoneTypeModelOpen();
                }
              }}
              value={patientDetails.phoneNumber}
              name="phoneNumber"
            />

            {/* <TextField
              {...validationParams?.register("phoneNumber")}
              error={
                validationParams?.errors?.phoneNumber?.message ? true : false
              }
              helperText={validationParams?.errors?.phoneNumber?.message?.toString()}
              name="phoneNumber"
              id="phoneNumber"
              label="PhoneNumber"
              value={patientDetails.phoneNumber}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
              onBlurCapture={() => {
                if (patientDetails.phoneNumber !== "") {
                  handlePhoneTypeModelOpen();
                }
              }}
            /> */}
          </Grid>
          {isNewUser && patientDetails.email != "" && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("password")}
                  error={
                    validationParams?.errors?.password?.message ? true : false
                  }
                  helperText={validationParams?.errors?.password?.message?.toString()}
                  name="password"
                  id="password"
                  label="Password"
                  type="password"
                  value={patientDetails.password}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("confirmPassword")}
                  error={
                    validationParams?.errors?.confirmPassword?.message
                      ? true
                      : false
                  }
                  helperText={validationParams?.errors?.confirmPassword?.message?.toString()}
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  value={patientDetails.confirmPassword}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Typography variant="h6" sx={{ marginLeft: "25px" }}>
                It looks like you are new to our service. Please create password
                for your account.
              </Typography>
            </>
          )}
        </Grid>
      </div>
      <div>
        <Typography variant="h4">Patient Location</Typography>
        <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
          {requestTypeId !== CreateRequestType.concierge && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("street")}
                  error={
                    validationParams?.errors?.street?.message ? true : false
                  }
                  helperText={validationParams?.errors?.street?.message?.toString()}
                  name="street"
                  id="street"
                  label="Street"
                  value={patientDetails.street}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("city")}
                  error={validationParams?.errors?.city?.message ? true : false}
                  helperText={validationParams?.errors?.city?.message?.toString()}
                  name="city"
                  id="city"
                  label="City"
                  value={patientDetails.city}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("state")}
                  error={
                    validationParams?.errors?.state?.message ? true : false
                  }
                  helperText={validationParams?.errors?.state?.message?.toString()}
                  name="state"
                  id="state"
                  label="State"
                  value={patientDetails.state}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("zipCode")}
                  error={
                    validationParams?.errors?.zipCode?.message ? true : false
                  }
                  helperText={validationParams?.errors?.zipCode?.message?.toString()}
                  name="zipCode"
                  id="zipCode"
                  label="Zip Code"
                  value={patientDetails.zipCode}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              name="room"
              id="room"
              label="Room # / Suite (Optional)"
              value={patientDetails.room}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
          {/* {requestTypeId !== CreateRequestType.concierge && (
            <Grid item xs={12} sm={6}>
              <div className="verify-btn-group">
                <Tooltip title={"Open Map"}>
                  <Button variant="outlined" onClick={handleOnClickOpenMap}>
                    <img src={LocationIcon} alt="location" />
                    Map
                  </Button>
                </Tooltip>
              </div>
            </Grid>
          )} */}
        </Grid>
      </div>
      {isShowUploadOption && (
        <div>
          <Typography variant="h4">
            (Optional) Upload Photo or Document
          </Typography>
          <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
            <Grid item xs={12} sm={12}>
              <UploadDocument
                accept="*"
                fileName={patientDetails.documentName}
                isMultiple={false}
                onSelectNewFiles={onSelectNewFiles}
              />
            </Grid>
          </Grid>
        </div>
      )}
      <PhoneTypeModal
        phoneTypeModelOpen={phoneTypeModelOpen}
        handlePhoneTypeModelClose={handlePhoneTypeModelClose}
        onSelectPhoneType={onRadioChange}
      ></PhoneTypeModal>
      <SelectResponsiblePartyModal
        responsiblePartyModalOpen={responsiblePartyModalOpen}
        handleResponsiblePartyModalClose={() =>
          setResponsiblePartyModalOpen(false)
        }
        onSelectResponsibleParty={onSelectResponsibleParty}
        isConcierge={requestTypeId === CreateRequestType.concierge}
      />
    </>
  );
};

export default PatientDetails;
