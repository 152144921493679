import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  CloseWhiteIcon,
  LinkIcon,
  MaximizeIcon,
  MinimizeIcon,
  MoodIcon,
  Profile,
  SendIcon,
  User,
  VerticalMenuIcon,
} from "../../assests/images";
import { ChatMenu } from "./chat-menu";
import { IChatBoxInfo } from "../../utility/interfaces/common/chat-box-info";
import { UserContext } from "../../contexts/user-context";
import { AccountType } from "../../utility/enums/constants";
import { AudioVideoRecordModal } from "./audio-video-record-modal";
import moment from "moment";
import { CLOUD_URL } from "../../config";

type ComponentProps = {
  handleOnCloseChat: any;
  handleOnMinimized: any;
  chatBoxInfo: IChatBoxInfo;
  index: number;
  sendMessage: Function;
  onKeyHandler: Function;
  sendAudioVideo: Function;
};

export function ChatBox(props: ComponentProps) {
  // use Context
  const userrole = useContext(UserContext);

  // useStates
  const [chatMenu, setChatMenu] = useState<null | HTMLElement>(null);
  const [isChatMenuRight, setIsChatMenuRight] = useState(false);
  const [minimizeClass, setMinimizeClass] = useState("maximize-chatbox");
  const [message, setMessage] = useState("");
  const [isAudioVideoModalOpen, setIsAudioVideoModalOpen] = useState(false);

  // Extract Props
  const {
    handleOnCloseChat,
    chatBoxInfo,
    handleOnMinimized,
    index,
    sendMessage,
    onKeyHandler,
    sendAudioVideo,
  } = props;

  // Handled events and functions
  const handleOnClickMinimized = () => {
    if (chatBoxInfo.isMinimized) setMinimizeClass("maximize-chatbox");
    else setMinimizeClass("minimize-chatbox");
    handleOnMinimized(chatBoxInfo.receiverId, chatBoxInfo.senderId);
  };
  const chatMenuOpen = Boolean(chatMenu);
  const handleChatMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    isRightSide: boolean
  ) => {
    setIsChatMenuRight(isRightSide);
    setChatMenu(event.currentTarget);
  };

  const handleChatMenuClose = () => {
    setChatMenu(null);
  };

  const handleClickAudioVideoOpen = () => {
    setIsAudioVideoModalOpen(true);
  };

  const handleClickAudioVideoClose = () => setIsAudioVideoModalOpen(false);

  const handleSendAudioVideo = (mediaFile: Blob) => {
    console.log(mediaFile);
    let { senderId, receiverId, isAdmin, confirmationNumber } = chatBoxInfo;
    let roleId = userrole.userRoleId;
    sendAudioVideo(
      mediaFile,
      senderId,
      receiverId,
      isAdmin ? AccountType.Admin : AccountType.Physician,
      confirmationNumber,
      AccountType.Patient
    );
  };

  // useEffects
  useEffect(() => {
    if (chatBoxInfo.isMinimized) setMinimizeClass("minimize-chatbox");
    else setMinimizeClass("maximize-chatbox");
  }, [chatBoxInfo]);

  return (
    <div className={`chat-popover chat-${index} ${minimizeClass}`}>
      <div className="popover-header">
        <Typography variant="h5">
          <img
            src={
              !chatBoxInfo.receiverProfilePath
                ? User
                : chatBoxInfo.receiverProfilePath
            }
            alt="profile"
          />
          {chatBoxInfo.receiverName}
        </Typography>
        <div className="chat-action-group">
          {chatBoxInfo.isMinimized ? (
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={handleOnClickMinimized}
            >
              <img src={MaximizeIcon} alt="clmaximizeose" />
            </IconButton>
          ) : (
            <IconButton
              disableFocusRipple
              disableRipple
              className="chatbox-size-btn minimize-btn"
              onClick={handleOnClickMinimized}
            >
              <img src={MinimizeIcon} alt="minimize" />
            </IconButton>
          )}
          <IconButton
            onClick={(e) => {
              handleOnCloseChat(
                chatBoxInfo.receiverId,
                chatBoxInfo.senderId,
                AccountType.Patient,
                chatBoxInfo.isAdmin ? AccountType.Admin : AccountType.Physician
              );
            }}
            disableFocusRipple
            disableRipple
          >
            <img src={CloseWhiteIcon} alt="close" />
          </IconButton>
        </div>
      </div>
      <div className="popover-body">
        <div className="popover-scroll">
          <div className="popover-scroll-inner">
            {chatBoxInfo.chatView.map((item, index) => {
              const cdate = new Date(item.createdDate);
              const today = new Date();
              return chatBoxInfo.chatView[index].senderTypeId ==
                parseInt(AccountType.Patient.toString()) ? (
                <div className="messages right">
                  <IconButton
                    onClick={(e) => handleChatMenuClick(e, true)}
                    aria-haspopup="true"
                    disableFocusRipple
                    disableRipple
                  >
                    <img src={VerticalMenuIcon} alt="menu" />
                  </IconButton>
                  <div
                    className="message"
                    dangerouslySetInnerHTML={{ __html: item.message }}
                  ></div>
                  <Typography variant="body1">
                    {moment(today).format("DD-MM-YYYY") ===
                    moment(cdate).format("DD-MM-YYYY")
                      ? new Intl.DateTimeFormat("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(cdate)
                      : new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(cdate)}
                  </Typography>
                </div>
              ) : (
                <div className="messages left">
                  <IconButton
                    onClick={(e) => handleChatMenuClick(e, true)}
                    aria-haspopup="true"
                    disableFocusRipple
                    disableRipple
                  >
                    <img src={VerticalMenuIcon} alt="menu" />
                  </IconButton>
                  <div
                    className="message"
                    dangerouslySetInnerHTML={{ __html: item.message }}
                  ></div>
                  <Typography variant="body1">
                    {moment(today).format("DD-MM-YYYY") ===
                    moment(cdate).format("DD-MM-YYYY")
                      ? new Intl.DateTimeFormat("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(cdate)
                      : new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(cdate)}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="popover-footer">
        <TextField
          placeholder="Type a message"
          id="chat"
          fullWidth
          className="with-icon-left"
          value={message}
          onKeyDown={(e) => {
            onKeyHandler(
              e,
              message,
              chatBoxInfo.senderId,
              chatBoxInfo.receiverId,
              chatBoxInfo.isAdmin ? AccountType.Admin : AccountType.Physician,
              chatBoxInfo.confirmationNumber,
              AccountType.Patient
            ).then(() => {
              if (e.code == "Enter") {
                setMessage("");
              }
            });
          }}
          onChange={(e) => setMessage(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="end" disableFocusRipple disableRipple>
                  <img src={MoodIcon} alt="smiley" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          className="contained"
          onClick={() => {
            sendMessage(
              message,
              chatBoxInfo.senderId,
              chatBoxInfo.receiverId,
              chatBoxInfo.isAdmin ? AccountType.Admin : AccountType.Physician,
              chatBoxInfo.confirmationNumber,
              AccountType.Patient
            ).then(() => setMessage(""));
          }}
        >
          <img src={SendIcon} alt="send" />
        </IconButton>
        <IconButton className="outlined" onClick={handleClickAudioVideoOpen}>
          <img src={LinkIcon} alt="link" />
        </IconButton>
      </div>
      <ChatMenu
        handleChatMenuClose={handleChatMenuClose}
        isRight={isChatMenuRight}
        chatMenu={chatMenu}
        chatMenuOpen={chatMenuOpen}
      />
      {/* Page modals */}
      <AudioVideoRecordModal
        isAudioVideoModalOpen={isAudioVideoModalOpen}
        handleClickAudioVideoClose={handleClickAudioVideoClose}
        handleSendAudioVideo={handleSendAudioVideo}
      />
    </div>
  );
}
